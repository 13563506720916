<template>
  <div class="floor2">

<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 636.25 285.58">
  <defs>
   
  </defs>
  <g id="C-236">
    <polygon id="C236" class="cls-26" points="575.74 100.81 575.86 94.95 596.34 94.95 593.15 94.84 593.47 92.35 596.34 92.22 596.27 83 569.13 83 561.48 90.7 543.92 90.7 543.92 102.5 552.79 110.93 570.16 110.87 571.12 110.84 571.28 100.77 575.74 100.81"/>
  </g>
  <text class="cls-17" transform="translate(552.45 104.85)"><tspan x="0" y="0">C-236</tspan></text>
  <text class="cls-17" transform="translate(550.05 99.82)"><tspan x="0" y="0">Lighting</tspan></text>
  <text class="cls-17" transform="translate(545.18 95.35)"><tspan x="0" y="0">Wired Custom</tspan></text>
  <polygon class="cls-26" points="445.19 88.66 444.91 88.55 444.92 88.71 474.47 88.66 445.19 88.66"/>
  <polygon class="cls-26" points="575.86 94.95 575.74 100.81 571.28 100.77 571.12 110.84 575.91 110.74 578.33 110.8 591.81 110.87 596.53 110.8 596.66 94.97 596.34 94.95 575.86 94.95"/>
  <g id="C-262">
    <polygon id="C262" class="cls-26" points="486.31 154.84 486.31 198.13 488.48 197.64 497.31 195.85 504.59 194.19 529.51 188.54 529.51 154.81 486.31 154.84"/>
  </g>
  <text class="cls-10" transform="translate(492.07 169.08)"><tspan x="0" y="0">Eggersmann</tspan></text>
  <text class="cls-10" transform="translate(496.84 175.19)"><tspan x="0" y="0">Kitchens</tspan></text>
  <text class="cls-10" transform="translate(499.4 182.13)"><tspan x="0" y="0">C-262</tspan></text>
  <g id="C-265">
    <polygon id="C-265-2" data-name="C-265" class="cls-26" points="529.51 154.81 529.51 188.54 531.92 188 544.03 185.04 546.92 184.87 547.08 181.68 543.92 154.8 529.51 154.81"/>
    <polygon class="cls-26" points="595.78 134.1 543.97 134.1 543.92 154.8 547.08 181.68 550.5 181.68 550.5 182.76 550.56 183.78 563.71 183.85 564.61 180.14 595.83 173.89 595.83 173.12 595.78 134.1"/>
  </g>
  <g id="C-265-3" data-name="C-265">
    <text class="cls-20" transform="translate(534.72 182.13) rotate(-90.29)"><tspan x="0" y="0">Lara Sofi</tspan></text>
    <text class="cls-19" transform="translate(539.72 184.93) rotate(-90.29)"><tspan x="0" y="0">Kitchens </tspan></text>
    <text class="cls-12" transform="translate(539.72 166.75) rotate(-90.29)"><tspan x="0" y="0">C-265</tspan></text>
  </g>
  <polygon id="C-232" class="cls-26" points="596.21 74.21 591.42 73.83 582.48 44.59 561.79 44.59 561.79 61.36 550.91 61.38 541.43 70.94 541.43 78.39 543.92 78.43 543.92 90.7 561.48 90.7 569.13 83 596.27 83 596.21 74.21"/>
  <text class="cls-16" transform="translate(555.87 76.22)"><tspan x="0" y="0">Scavolini</tspan></text>
  <text class="cls-16" transform="translate(558.95 70.35)"><tspan x="0" y="0">C-232</tspan></text>
  <g id="C-272">
    <polygon id="C272" class="cls-26" points="447.48 154.86 447.63 175.74 444.89 175.67 444.89 185.03 458.74 185.19 458.93 185.79 458.93 154.86 447.48 154.86"/>
  </g>
  <polygon points="450.23 175.78 450.97 177.18 450.24 178.58 451.8 178.3 452.92 179.43 453.13 177.87 454.55 177.15 453.12 176.46 452.89 174.89 451.79 176.04 450.23 175.78"/>
  <path class="cls-3" d="M452.7,177.09s-7.97,7.59-.32,21.76"/>
  <text class="cls-11" transform="translate(442.05 202.51) rotate(-.14)"><tspan x="0" y="0">Holzsch</tspan></text>
  <text class="cls-21" transform="translate(445.87 207.67) rotate(-.14)"><tspan x="0" y="0">C-272</tspan></text>
  <g id="C-270">
    <polygon id="C270" class="cls-26" points="458.93 154.86 458.93 185.79 459.44 187.42 460.83 190.33 462.19 191.96 463.79 193.93 466.51 196.29 470.7 198.57 475.48 199.67 481.65 199.16 486.31 198.13 486.31 154.84 458.93 154.86"/>
  </g>
  <text class="cls-11" transform="translate(463.42 180.18)"><tspan x="0" y="0">C-270 </tspan></text>
  <text class="cls-11" transform="translate(460.27 173.92)"><tspan x="0" y="0">Snaidero</tspan></text>
  <polygon class="cls-26" points="455.27 97.04 448.01 97.09 448.01 145.35 448.01 148.73 463.74 148.78 463.74 105.28 455.27 97.04"/>
  <g>
    <polygon class="cls-5" points="447.95 97.09 448.01 97.09 448.01 96.51 447.95 97.09"/>
    <polygon class="cls-26" points="445.27 89.35 448.01 92.09 448.01 96.51 448.42 92.18 445.27 89.35"/>
  </g>
  <polygon class="cls-26" points="415.69 89.13 444.33 89.13 448.05 92.32 447.57 148.4 415.98 148.51 415.69 89.13"/>
  <g id="C-230">
    <polygon id="C230" class="cls-26" points="550.91 61.38 561.79 61.36 561.79 44.59 540.79 44.59 535.23 44.46 535.04 47.08 531.72 47.08 529.04 49.95 529.11 78.17 541.43 78.39 541.43 70.94 550.91 61.38"/>
  </g>
  <g id="C-212">
    <polygon id="C212" class="cls-26" points="472.52 113.83 463.74 105.28 463.74 148.78 488.04 148.86 497.3 141.07 497.3 113.83 472.52 113.83"/>
  </g>
  <text class="cls-15" transform="translate(475.22 135.82)"><tspan x="0" y="0">C-212</tspan></text>
  <text class="cls-15" transform="translate(467.42 124.07)"><tspan x="0" y="0">Downsview </tspan><tspan x="0" y="5.69">Kitchens</tspan></text>
  <g id="C-214">
    <polygon id="C214" class="cls-26" points="474.47 97.66 474.47 88.66 444.92 88.71 444.95 89.03 445.27 89.35 448.42 92.18 448.01 96.51 448.01 97.09 455.27 97.04 472.52 113.83 497.3 113.83 497.3 97.66 474.59 97.66 474.47 97.66"/>
  </g>
  <g id="C-220">
    <polygon id="C220" class="cls-26" points="497.37 78.17 499.07 78.14 499.07 70.35 489.83 61.2 479.74 61.2 479.74 49.39 477.01 48.35 477 48.48 444.82 56.78 444.89 88.27 444.91 88.55 445.19 88.66 474.47 88.66 474.59 97.66 497.3 97.66 497.3 95.35 497.37 78.17"/>
  </g>
  <text class="cls-13" transform="translate(462.86 77.49)"><tspan x="0" y="0">C-220</tspan></text>
  <text class="cls-13" transform="translate(448.78 71.36)"><tspan x="0" y="0">Armazem Design</tspan></text>
  <g id="C-224">
    <polygon id="C224" class="cls-26" points="508.47 48.77 508.11 47.52 504.98 47.27 504.98 44.46 476.82 44.52 476.51 48.22 479.25 49.26 479.25 61.08 489.33 61.08 498.57 70.23 498.57 78.01 503.19 77.92 511.88 77.96 511.88 48.89 508.47 48.77"/>
  </g>
  <text class="cls-13" transform="translate(492.27 59.46)"><tspan x="0" y="0">C-224</tspan></text>
  <polygon id="Star" points="494.36 54.63 493.52 53.29 491.94 53.22 492.96 52.01 492.54 50.48 494.01 51.07 495.33 50.2 495.22 51.78 496.46 52.77 494.92 53.15 494.36 54.63"/>
  <path id="Line" d="M492.96,51.72c-5.89-2.15-10.21-4.11-13.19-5.9-2.99-1.79-4.64-3.44-5.22-4.9l-.22,.14c.64,1.58,2.33,3.22,5.33,5.03,3,1.81,7.33,3.77,13.23,5.92l.07-.29Z"/>
  <text class="cls-13" transform="translate(446.75 40.24)"><tspan x="0" y="0">NEFF Beautiful Living</tspan></text>
  <g id="C-228B">
    <polygon id="C228B" class="cls-26" points="512.38 78.09 528.98 78.17 529.01 63.03 512.38 63.03 512.38 65.92 512.38 78.09"/>
  </g>
  <text class="cls-18" transform="translate(513.71 73.96)"><tspan x="0" y="0">C-228B</tspan></text>
  <text class="cls-22" transform="translate(507.51 23.79)"><tspan x="0" y="0">Edward Fields</tspan></text>
  <g id="C-228A">
    <polygon id="C228A" class="cls-26" points="517.6 49.02 512.38 49.02 512.38 63.03 529.01 63.03 529.04 49.19 517.6 49.02"/>
  </g>
  <text class="cls-18" transform="translate(513.71 60.16)"><tspan x="0" y="0">C-228A</tspan></text>
  <text class="cls-13" transform="translate(475.22 30.63)"><tspan x="0" y="0">Tai Ping Carpets</tspan></text>
  <polygon points="522.34 70.35 521.5 69.01 519.92 68.94 520.94 67.73 520.51 66.2 521.98 66.79 523.3 65.92 523.19 67.5 524.43 68.49 522.89 68.87 522.34 70.35"/>
  <path class="cls-1" d="M531.97,25.61s7.06,32.47-9.43,42.19"/>
  <polygon points="521.66 54.89 520.61 53.71 519.03 53.91 519.83 52.54 519.16 51.11 520.71 51.44 521.86 50.36 522.02 51.94 523.41 52.7 521.96 53.34 521.66 54.89"/>
  <path class="cls-1" d="M520.37,52.57s-13.02-5.4-14.47-20.86"/>
  <g id="C-250">
    <polygon id="C250" class="cls-26" points="595.77 126.45 593.28 126.51 593.15 124.72 596.47 124.72 596.47 115.97 592.19 115.97 581.02 115.85 570.29 115.91 553.5 115.97 552.6 116.04 543.98 126.38 543.97 134.1 595.78 134.1 595.77 126.45"/>
  </g>
  <text class="cls-10" transform="translate(552.97 123.25)"><tspan x="0" y="0">SieMatic</tspan></text>
  <text class="cls-14" transform="translate(557.61 128.83)"><tspan x="0" y="0">C-250</tspan></text>
  <g id="B-260">
    <polygon id="B260" class="cls-29" points="415.62 201.84 396.9 201.84 371.47 174.5 364.1 174.5 364.1 191.44 284.2 191.76 284.39 226.81 306.45 232.35 332.64 238.69 352.08 243.19 355.32 243.82 359.28 244.59 361.76 244.81 366.3 244.63 369.32 244 371.07 243.28 374.54 241.48 377.19 239.59 380.39 236.49 382.14 233.79 383.22 230.95 384.08 226.72 384.45 223.03 410.85 223.03 410.85 227.53 415.62 227.62 415.62 201.84"/>
  </g>
  <text class="cls-13" transform="translate(335.78 215.41)"><tspan x="0" y="0">Baker Furniture</tspan></text>
  <text class="cls-13" transform="translate(344.53 222.49)"><tspan x="0" y="0">B-260</tspan></text>
  <g id="B-254">
    <polygon id="B254" class="cls-29" points="397.23 202.07 416.17 202.07 416.13 175.43 415.98 165.29 397.3 165.29 397.3 154.91 389 154.84 387.92 154.99 371.71 154.91 367.78 158.64 364.79 161.45 364.79 162.14 364.83 174.73 371.8 174.73 397.23 202.07"/>
  </g>
  <text class="cls-13" transform="translate(374.35 171.63)"><tspan x="0" y="0">Cowtan &amp; Tout</tspan></text>
  <text class="cls-13" transform="translate(391.73 178.85)"><tspan x="0" y="0">B-254</tspan></text>
  <g id="B-228">
    <polygon id="B228" class="cls-29" points="332.35 62.89 325.8 61.37 320.04 60.31 316.3 59.24 313.84 58.64 309.05 58.44 306.29 58.53 302.92 59.15 299.9 60.13 296.88 61.2 294.12 62.62 291.4 64.54 289.6 67.18 287.62 69.65 285.91 72.33 284.72 74.78 284.62 90.9 300.41 106.86 300.41 110.95 302.1 110.95 302.24 111 302.24 110.98 317.46 110.82 348.04 110.87 348.04 66.69 332.35 62.89"/>
  </g>
  <text class="cls-13" transform="translate(311.21 90.18)"><tspan x="0" y="0">B-228</tspan></text>
  <text class="cls-13" transform="translate(294.37 83)"><tspan x="0" y="0">Century Furniture</tspan></text>
  <g id="B-218">
    <polygon id="B218" class="cls-29" points="272.58 80.18 272.58 89.89 268.29 89.89 268.29 103.23 273.27 103.23 284.3 114.27 284.34 121.1 299.16 135.82 302.17 135.82 301.98 110.95 300.41 110.95 300.41 110.79 300.41 106.86 284.67 91.08 284.67 90.95 284.62 90.9 284.67 82.87 284.67 80.18 272.58 80.18"/>
  </g>
  <text class="cls-24" transform="translate(289.16 117.47) rotate(45)"><tspan x="0" y="0">B-218</tspan></text>
  <polygon id="B-216" class="cls-29" points="299.46 135.69 284.64 120.97 284.59 114.14 273.57 103.1 268.59 103.1 268.59 148.71 291.01 148.74 302.46 140.05 302.47 135.69 299.46 135.69"/>
  <g id="B-216-2" data-name="B-216">
    <text class="cls-16" transform="translate(270.47 129.83)"><tspan x="0" y="0">B-216</tspan></text>
    <text class="cls-16" transform="translate(270.47 135.79)"><tspan x="0" y="0">Bienal</tspan></text>
  </g>
  <polygon class="cls-29" points="268.59 89.75 272.88 89.75 272.88 80.04 284.97 80.04 285.01 74.64 259.58 74.31 255.94 89.86 250.76 90.38 250.74 99.24 234.98 99.16 205.54 98.93 205.62 148.66 268.59 148.71 268.59 103.1 268.59 89.75"/>
  <text class="cls-7" transform="translate(279.77 92.66) rotate(46.99)"><tspan x="0" y="0">Mantra</tspan></text>
  <text class="cls-9" transform="translate(272.15 92.66) rotate(46.99)"><tspan x="0" y="0">Furnishings </tspan></text>
  <g>
    <polygon class="cls-29" points="386.84 112.95 391.12 112.95 391.12 80.47 391.09 80.47 391.01 77.24 380.25 74.55 356.19 68.66 348.04 66.69 348.04 110.87 364.95 110.9 364.95 114.36 364.95 119.4 386.84 119.4 386.84 112.95"/>
    <polygon class="cls-29" points="411.22 75.72 413.42 75.72 411.2 75.67 411.22 80.47 411.22 80.47 411.22 75.72"/>
    <rect class="cls-5" x="391.12" y="80.47" width="20.1" height="0"/>
    <polygon class="cls-5" points="415.83 88.61 416.39 88.62 416.39 75.72 413.42 75.72 415.76 75.78 415.83 88.61"/>
  </g>
  <g id="B-240">
    <polygon id="B240" class="cls-29" points="416.2 89.11 415.64 89.11 415.57 76.27 413.23 76.22 411.03 76.22 411.03 80.96 411.03 80.96 411.03 80.96 390.94 80.96 390.94 113.45 386.65 113.45 386.65 119.9 364.76 119.9 364.76 142.6 371.45 149.44 387.9 149.36 388.74 149.36 391.05 149.27 390.97 135.84 397.35 135.84 399.81 135.84 401.42 137.37 415.72 137.37 415.73 137.76 416.2 137.37 416.2 89.11"/>
  </g>
  <text class="cls-23" transform="translate(393.95 112.52)"><tspan x="0" y="0">Ammon</tspan></text>
  <text class="cls-13" transform="translate(392.28 117.98)"><tspan x="0" y="0">Hickson</tspan></text>
  <text class="cls-13" transform="translate(393.95 124.44)"><tspan x="0" y="0">B-240</tspan></text>
  <g>
    <polygon class="cls-29" points="300.41 106.86 284.67 90.95 284.67 91.08 300.41 106.86"/>
    <polygon class="cls-5" points="284.67 90.95 284.67 82.87 284.62 90.9 284.67 90.95"/>
    <rect class="cls-5" x="300.41" y="110.79" width="1.58" height=".15" transform="translate(-.85 2.33) rotate(-.44)"/>
  </g>
  <polygon class="cls-26" points="517.6 49.02 529.04 49.19 528.98 78.17 512.38 78.09 512.38 78.18 529.12 78.18 529.12 49.02 517.6 49.02"/>
  <polyline class="cls-28" points="508.83 48.81 512.71 49.24 508.83 48.81"/>
  <polygon class="cls-31" points="39.9 154.52 82.52 154.4 87.85 159.45 87.77 171.85 87.88 195.84 157.92 195.98 157.93 171.79 157.82 159.37 163.42 154.61 170.66 154.44 194.87 154.44 290.3 154.44 300.68 162.96 301.01 191.51 284.18 191.59 284.14 226.83 277.89 225.21 277.81 222.45 269.58 222.41 269.54 227.77 250.39 227.77 250.19 213 205.09 212.92 205.01 227.85 187.04 227.69 187 222.33 176.94 222.33 176.9 225.78 170.09 227.4 132.09 236.69 114.69 240.79 102.85 243.54 95.03 244.94 89.3 244.8 84.07 243.34 79.92 240.97 75.35 237.14 72.7 233.53 71.55 231.13 70.89 228.98 70.57 227.2 70.28 225.54 70.2 223.43 44.41 223.35 44.46 227.4 39.13 227.4 39.16 213.43 39.26 158.41 39.3 154.52 39.9 154.52"/>
  <polygon class="cls-31" points="139.23 59.79 147.39 58.33 150.91 58.37 155 58.79 159.56 60.4 162.72 62.79 165.64 65.27 168.42 67.92 170.83 72.16 171.75 75.82 196.99 75.69 200.23 90.25 205.3 90.41 205.22 123.95 205.22 148.73 172.17 148.81 165.23 148.77 152.5 148.69 152.37 135.64 93.69 135.69 93.65 148.33 83.51 148.28 56.94 148.37 52.32 147.92 48.59 146.82 45.26 144.51 41.05 138.92 40.28 135.59 39.75 132.71 39.64 75.19 44.99 75.19 44.99 79.43 64.49 79.58 64.49 78.73 64.49 77.19 65.06 77.03 81.52 73.21 107.28 67.25 139.23 59.79"/>
  <path d="M39.82,132.02h.37v-56.42s4.28,0,4.28,0v4.3h20.39v-2.53s77.4-17.89,77.4-17.89l-.08-.36,.08,.36s.05,0,.15-.03c.68-.13,3.66-.66,7.15-.66,3.5,0,7.5,.54,10.23,2.47,3.03,2.14,5.79,4.35,7.83,6.73,2.04,2.38,3.37,4.91,3.59,7.73l.03,.34h25.33s3.32,14.58,3.32,14.58h4.98v8.38s.02,49.39,.02,49.39l-52.07,.04-.15-13.09h-59.37s0,12.64,0,12.64l-10.21-.09h-27.72c-3.35,0-5.92-1.04-7.75-2.15-.91-.56-1.64-1.14-2.18-1.62-.54-.48-.89-.86-1.08-1.04-1.95-1.86-3.01-4.63-3.55-6.95-.28-1.16-.42-2.2-.5-2.95-.04-.38-.06-.68-.07-.88-.01-.21-.02-.31-.02-.31h-.37s.37,0,.37,0h-.37s-.37,0-.37,0c0,.02,.05,1.91,.61,4.3,.57,2.39,1.64,5.29,3.77,7.32,.16,.15,.54,.56,1.1,1.06,1.7,1.51,5.17,3.96,10.43,3.96h27.72s10.96,.1,10.96,.1v-12.65s57.89,0,57.89,0l.15,13.09,53.56-.04-.02-50.14v-9.12h-5.13s-3.32-14.58-3.32-14.58h-25.58v.37l.37-.03c-.24-3.02-1.66-5.7-3.76-8.16-2.11-2.46-4.91-4.7-7.97-6.85-2.96-2.08-7.09-2.61-10.66-2.61-4.08,0-7.43,.7-7.45,.71h0s-77.98,18.03-77.98,18.03v2.38s-18.9,0-18.9,0v-4.3h-5.77v57.17s0,0,0,0h.37Z"/>
  <path d="M596.42,115.97h-.37s.04,8.44,.04,8.44h-3.34v2.43h2.63s0,46.75,0,46.75l-31.23,6.16-.72,3.65h-12.46s0-2.32,0-2.32h-4.33v3.27s-1.58,0-1.58,0h-.04s-.79,.2-2.19,.53c-9.85,2.35-50.16,11.89-64.17,14.19-.75,.12-1.49,.18-2.23,.18-4.51,0-8.66-2.22-11.76-5.11-3.1-2.88-5.13-6.46-5.44-8.99l-.04-.32-14.05-.05v-8.84s2.78,0,2.78,0v-20.81s96.44,0,96.44,0v-28.61s8.49-10.31,8.49-10.31l43.59,.12v-.37s-.37,0-.37,0h.37s0-.37,0-.37l-43.94-.13-8.89,10.79v28.13s-96.44,0-96.44,0v20.82s-2.78,0-2.78,0v10.33s14.47,.05,14.47,.05v-.37s-.37,.05-.37,.05c.36,2.81,2.47,6.45,5.67,9.44,3.2,2.98,7.5,5.31,12.27,5.31,.77,0,1.56-.06,2.35-.19,16.09-2.64,66.42-14.72,66.42-14.72l-.09-.36v.37h2.29v-3.27s2.84,0,2.84,0v2.32h13.82s.74-3.76,.74-3.76l31.34-6.18v-48.11h-2.63s0-.94,0-.94h3.35s-.04-9.56-.04-9.56h-.37s0,.37,0,.37Z"/>
  <polygon points="508.41 47.83 508.78 49.39 512.38 49.26 512.35 48.52 509.36 48.63 509.14 47.67 508.41 47.83"/>
  <polygon points="420.41 168.65 420.41 169.02 433.17 169.02 433.17 158.43 420.04 158.43 420.04 169.02 420.41 169.02 420.41 168.65 420.78 168.65 420.78 159.17 432.42 159.17 432.42 168.27 420.41 168.27 420.41 168.65 420.78 168.65 420.41 168.65"/>
  <polygon points="447.25 173.98 441.27 173.98 441.27 163.93 447.25 163.93 447.25 163.19 440.53 163.19 440.53 174.73 447.25 174.73 447.25 173.99 447.25 173.98"/>
  <polyline points="428.85 174.66 441.24 174.66 441.24 173.92 428.85 173.92"/>
  <polyline points="416.13 183.31 444.4 182.99 444.39 182.24 416.12 182.56"/>
  <polygon points="397.21 155.08 415.36 155.08 415.36 164.95 416.11 164.95 416.11 154.34 397.21 154.34 397.21 155.08"/>
  <line x1="152.18" y1="143.74" x2="152.18" y2="142.99"/>
  <line x1="151.79" y1="165.18" x2="152.53" y2="165.18"/>
  <polyline points="512.36 49.26 529.54 49.46 529.55 48.71 512.37 48.52"/>
  <polyline points="512.61 77.98 512.61 48.93 511.87 48.93 511.87 77.98"/>
  <polyline points="529.44 77.93 529.44 48.93 528.69 48.93 528.69 77.93"/>
  <polyline points="529.52 49.41 531.99 47.27 531.5 46.7 529.03 48.85"/>
  <polygon points="39.82 163.71 44.74 163.71 44.74 176.13 39.59 176.13 39.59 176.87 45.48 176.87 45.48 162.96 39.82 162.96 39.82 163.71"/>
  <polygon points="66.41 148.19 66.41 136.33 80.41 136.33 80.41 148.19 81.16 148.19 81.16 135.59 65.66 135.59 65.66 148.19 66.41 148.19"/>
  <polygon points="77.85 155.02 77.85 158.03 66.41 158.03 66.41 155.02 65.66 155.02 65.66 158.77 78.59 158.77 78.59 155.02 77.85 155.02"/>
  <polygon points="88.12 174.43 74.39 174.38 73.11 180.47 62.46 180.47 62.46 199.78 63.2 199.78 63.2 181.21 73.71 181.21 74.99 175.12 88.12 175.17 88.12 174.43 88.12 174.43"/>
  <polygon points="88.21 183.67 83.19 183.67 67.48 199.38 39.43 199.38 39.43 200.13 67.79 200.13 83.5 184.42 88.21 184.42 88.21 183.67"/>
  <polygon points="87.78 196.23 87.78 223.26 70.85 223.26 70.85 224 88.53 224 88.53 196.23 87.78 196.23"/>
  <polyline points="106.38 196.3 106.38 242.86 107.12 242.86 107.12 196.3"/>
  <polyline points="125.93 196.23 125.93 238.14 126.68 238.14 126.68 196.23"/>
  <polyline points="135.55 196.23 135.55 235.97 136.3 235.97 136.3 196.23"/>
  <polygon points="157.67 195.91 157.67 208.24 163.82 214.39 171.13 214.39 171.13 227.32 171.88 227.32 171.88 213.65 164.13 213.65 158.41 207.93 158.41 195.91 157.67 195.91"/>
  <polygon points="65.72 142.42 76.88 142.42 76.88 148.19 77.63 148.19 77.63 141.68 65.72 141.68 65.72 142.42"/>
  <line x1="94.3" y1="148.14" x2="93.55" y2="148.14"/>
  <polygon points="152.59 144.99 161.41 144.99 165.5 149.15 166.03 148.63 161.72 144.24 152.59 144.24 152.59 144.99"/>
  <polygon points="187.8 75.69 187.8 78.84 171.94 78.84 171.94 75.69 171.2 75.69 171.2 79.59 188.55 79.59 188.55 75.69 187.8 75.69"/>
  <polygon points="211.53 98.77 211.53 119.24 205.86 119.24 205.86 98.77 205.11 98.77 205.11 119.98 212.27 119.98 212.27 98.77 211.53 98.77"/>
  <polygon points="250.13 99.09 250.13 119.24 244.33 119.24 244.33 99.09 243.58 99.09 243.58 119.98 250.87 119.98 250.87 99.09 250.13 99.09"/>
  <polygon points="250.7 119.98 252.26 119.95 252.24 148.5 252.98 148.5 253.01 119.19 250.68 119.24 250.7 119.98"/>
  <polygon points="171.51 166.41 171.51 166.79 179.57 166.79 179.57 154.5 171.13 154.5 171.13 166.79 171.51 166.79 171.51 166.41 171.88 166.41 171.88 155.24 178.83 155.24 178.83 166.04 171.51 166.04 171.51 166.41 171.88 166.41 171.51 166.41"/>
  <polygon points="179.2 166.41 179.2 166.79 187.27 166.79 187.27 154.5 178.83 154.5 178.83 166.79 179.2 166.79 179.2 166.41 179.57 166.41 179.57 155.24 186.52 155.24 186.52 166.04 179.2 166.04 179.2 166.41 179.57 166.41 179.2 166.41"/>
  <polygon points="194.59 159.68 194.22 159.68 194.22 166.04 187.27 166.04 187.27 155.24 194.22 155.24 194.22 159.68 194.59 159.68 194.96 159.68 194.96 154.5 186.52 154.5 186.52 166.79 194.96 166.79 194.96 159.68 194.59 159.68"/>
  <polygon points="194.58 159.68 202.87 159.73 202.87 165.82 206.18 165.82 206.18 155.1 205.44 155.1 205.43 165.08 203.62 165.08 203.62 158.99 194.59 158.94 194.59 159.68 194.58 159.68"/>
  <polygon points="284.67 80.18 284.67 79.81 274.73 79.81 268.05 79.81 268.05 75.1 284.3 75.1 284.3 80.18 284.67 80.18 284.67 79.81 284.67 80.18 285.04 80.18 285.04 74.36 267.31 74.36 267.31 80.55 274.73 80.55 285.04 80.55 285.04 80.18 284.67 80.18"/>
  <polygon points="268.69 148.63 268.69 90.25 272.98 90.25 272.98 80.48 272.24 80.48 272.24 89.5 267.95 89.5 267.95 148.63 268.69 148.63"/>
  <polygon points="284.3 80.18 284.3 91.23 300.01 106.94 300.01 111.19 301.98 111.19 301.98 110.45 300.75 110.45 300.75 106.63 285.04 90.92 285.04 80.18 284.3 80.18"/>
  <polygon points="381.43 74.88 381.43 91.03 364.53 91.03 364.53 110.63 365.28 110.63 365.28 91.77 382.18 91.77 382.18 74.88 381.43 74.88"/>
  <polygon points="396.82 135 396.82 148.39 391 148.39 391 149.14 397.56 149.14 397.56 135 396.82 135 396.82 135"/>
  <polygon points="415.78 148.43 407.5 148.43 407.5 136.6 406.76 136.6 406.76 149.18 415.78 149.18 415.78 148.43"/>
  <polygon points="403.54 165.01 403.48 157.45 406.85 157.49 406.9 154.96 406.15 154.95 406.12 156.74 402.73 156.69 402.8 165.02 403.54 165.01"/>
  <polygon points="457.41 185.33 457.43 194.31 423.21 194.25 423.21 182.89 422.46 182.89 422.46 195 458.17 195.06 458.15 185.33 457.41 185.33 457.41 185.33"/>
  <polygon points="476.91 48.66 479.53 49.66 479.53 61.64 489.69 61.64 498.76 70.4 498.76 78.11 499.51 78.11 499.51 70.08 489.99 60.89 480.27 60.89 480.27 49.15 477.18 47.96 476.91 48.66"/>
  <polygon points="448.18 92.4 455.16 92.4 455.16 96.8 448.36 96.8 448.36 97.54 455.91 97.54 455.91 91.66 448.18 91.66 448.18 92.4"/>
  <polygon points="455.27 97.43 472.05 114.21 497.03 114.21 497.03 113.47 472.36 113.47 455.8 96.91 455.27 97.43"/>
  <polyline points="485.94 154.52 485.94 197.85 486.68 197.85 486.68 154.52"/>
  <polyline points="529.14 154.88 529.14 188.63 529.88 188.63 529.88 154.88"/>
  <polygon points="541.82 78.17 541.82 71.04 551.06 61.8 562.12 61.8 562.12 44.59 561.38 44.59 561.38 61.05 550.75 61.05 541.08 70.73 541.08 78.17 541.82 78.17"/>
  <polygon points="543.69 91.13 561.48 91.13 569.17 83.44 595.95 83.44 595.95 82.69 568.86 82.69 561.17 90.38 543.69 90.38 543.69 91.13"/>
  <polygon points="571.64 110.95 571.64 101.07 576.12 101.07 576.12 95.3 592.74 95.3 592.74 94.55 575.38 94.55 575.38 100.32 570.89 100.32 570.89 110.95 571.64 110.95"/>
  <polyline points="577.62 95.25 577.62 110.82 578.37 110.82 578.37 95.25"/>
  <polygon points="592.82 124.35 583.5 124.35 583.5 116.1 582.75 116.1 582.75 125.09 592.82 125.09 592.82 124.35"/>
  <polygon points="562.61 143.73 562.61 144.1 568.75 144.1 568.75 138.23 562.23 138.23 562.23 144.1 562.61 144.1 562.61 143.73 562.98 143.73 562.98 138.97 568.01 138.97 568.01 143.36 562.61 143.36 562.61 143.73 562.98 143.73 562.61 143.73"/>
  <polygon points="586.33 144.05 586.33 144.42 592.15 144.42 592.15 138.55 585.96 138.55 585.96 144.42 586.33 144.42 586.33 144.05 586.7 144.05 586.7 139.29 591.41 139.29 591.41 143.68 586.33 143.68 586.33 144.05 586.7 144.05 586.33 144.05"/>
  <polygon points="474.59 97.66 474.59 98.03 481.12 98.03 481.12 91.84 474.22 91.84 474.22 98.03 474.59 98.03 474.59 97.66 474.96 97.66 474.96 92.59 480.37 92.59 480.37 97.28 474.59 97.28 474.59 97.66 474.96 97.66 474.59 97.66"/>
  <polygon points="481.05 97.7 481.05 98.07 493.59 98.07 493.59 90.64 480.68 90.64 480.68 98.07 481.05 98.07 481.05 97.7 481.42 97.7 481.42 91.39 492.84 91.39 492.84 97.33 481.05 97.33 481.05 97.7 481.42 97.7 481.05 97.7"/>
  <polygon points="497.42 97.34 493.74 97.34 493.74 93.94 497.42 93.94 497.42 93.19 492.99 93.19 492.99 98.08 497.42 98.08 497.42 97.34"/>
  <line x1="152.26" y1="149.21" x2="152.26" y2="148.46"/>
  <polyline points="348.41 111.08 348.41 66.97 347.67 66.97 347.67 111.08"/>
  <polyline points="458.56 154.64 458.56 185.21 459.3 185.21 459.3 154.64"/>
  <line class="cls-25" x1="442.38" y1="148.99" x2="439.02" y2="147.93"/>
  <line class="cls-25" x1="497.57" y1="88.47" x2="496.51" y2="85.1"/>
  <line class="cls-25" x1="497.57" y1="81.35" x2="496.51" y2="84.71"/>
  <polyline points="416.06 136.88 416.06 88.78 415.32 88.78 415.32 136.88"/>
  <polyline points="430.4 88.66 430.19 148.86 430.93 148.86 431.14 88.66"/>
  <polygon points="364.74 175.1 371.47 175.14 397.05 202.51 415.74 202.51 415.74 201.76 397.37 201.76 371.8 174.39 364.74 174.36 364.74 175.1 364.74 175.1"/>
  <polygon points="218.26 154.87 218.26 177.69 237.92 177.69 237.92 154.87 237.17 154.87 237.17 176.94 219 176.94 219 154.87 218.26 154.87"/>
  <rect x="218.63" y="168.93" width="18.59" height=".74"/>
  <rect x="226.59" y="154.87" width=".74" height="14.43"/>
  <polygon points="226.97 159.36 226.97 159.73 234.07 159.73 234.07 154.5 226.59 154.5 226.59 159.73 226.97 159.73 226.97 159.36 227.34 159.36 227.34 155.24 233.33 155.24 233.33 158.99 226.97 158.99 226.97 159.36 227.34 159.36 226.97 159.36"/>
  <path class="cls-25" d="M263.7,154.4l-3.36,1.07,3.36-1.07Z"/>
  <path class="cls-25" d="M256.58,154.42l3.37,1.05-3.37-1.05Z"/>
  <path class="cls-25" d="M301.65,189.8l-1.06-3.36,1.06,3.36Z"/>
  <polygon points="390.6 79.21 390.6 112.6 386.4 112.6 386.4 119.06 365.33 119.06 365.33 119.8 387.14 119.8 387.14 113.35 391.33 113.35 391.33 79.21 390.6 79.21"/>
  <polygon points="444.15 89.01 474.15 89.01 474.15 92.21 474.89 92.21 474.89 88.26 444.15 88.26 444.15 89.01"/>
  <polyline class="cls-30" points="233.33 158.99 227.34 158.99 227.34 155.3 233.33 155.3 233.33 158.99"/>
  <polyline class="cls-30" points="237.17 168.93 227.34 168.93 227.34 159.73 234.07 159.73 234.07 154.96 237.17 154.87 237.17 168.93"/>
  <rect class="cls-30" x="219" y="169.76" width="18.17" height="7.27"/>
  <path d="M293.27,157.18l-2.38-1.95h.25s2.24,1.82,2.24,1.82l-.11,.13m3.07,2.5l-.08-.07h.07s.01,.07,.01,.07m4.48,22.65h-.11s.27-18.87,.27-18.87l-1.65-1.35,.12-.15,1.56,1.27-.05,10.88-.13,8.22m-.06,3.96l-.05-.03s-.03,0-.05,.01v-.07s.03,0,.05,.01l.05-.03v.12m-49.68,26.65h-1.54s1.54,0,1.54,0h0m27.56,9.42l-8.01-.09h8.01s0,.09,0,.09m6.02,4.28l-.43-.11v-35.09s16.35,0,16.35,0l.02-1.28h.1s-.02,1.29-.02,1.29h-16.02v35.2m-15.02,.93v-5.31s.59,0,.59,0l-.09,5.3h-.5"/>
  <path class="cls-32" d="M219,160.92h-.63s0-5.61,0-5.61h.64s0,5.61,0,5.61m-.63,3.67v-.09s.06,0,.1,0h-.01s.04,.04,.04,.04l-.04,.03h0s-.07,.01-.09,.01m.63,.03s-.02,0-.03,0l-.06-.07,.06-.08s.02,0,.03,0v.16m18.91,13.06h-19.52s-.01-9.51-.01-9.51h.62s0,.75,0,.75h-.37v.74h.37s0,7.27,0,7.27h18.17v-7.27s.05,0,.05,0v-.74h-.05s0-13.64,0-13.64h.74s0,22.39,0,22.39"/>
  <polygon class="cls-32" points="237.22 169.67 237.17 169.67 219 169.67 218.63 169.67 218.63 168.93 219 168.93 226.59 168.93 226.59 169.3 227.34 169.3 227.34 168.93 237.17 168.93 237.22 168.93 237.22 169.67"/>
  <polygon class="cls-32" points="227.34 169.3 226.59 169.3 226.59 168.93 226.59 155.3 226.59 159.73 226.97 159.73 227.34 159.73 227.34 168.93 227.34 169.3"/>
  <polygon class="cls-32" points="234.07 159.73 227.34 159.73 226.97 159.73 226.59 159.73 226.59 155.3 227.34 155.3 227.34 158.99 233.33 158.99 233.33 155.29 234.07 155.29 234.07 159.73"/>
  <polygon points="218.26 154.96 218.26 177.78 237.92 177.78 237.92 154.96 237.17 154.96 237.17 177.03 219 177.03 219 154.96 218.26 154.96"/>
  <polyline points="218.63 169.76 237.22 169.76 237.22 169.02 218.63 169.02"/>
  <polyline points="226.59 154.96 226.59 169.39 227.34 169.39 227.34 154.96"/>
  <polygon points="226.97 159.45 226.97 159.83 234.07 159.83 234.07 154.59 226.59 154.59 226.59 159.83 226.97 159.83 226.97 159.45 227.34 159.45 227.34 155.34 233.33 155.34 233.33 159.08 226.97 159.08 226.97 159.45 227.34 159.45 226.97 159.45"/>
  <polygon points="268.51 103.62 273.16 103.62 284 114.46 284 121.36 298.89 136.24 302.23 136.24 302.23 135.5 299.2 135.5 284.75 121.05 284.75 114.16 273.47 102.88 268.51 102.88 268.51 103.62"/>
  <line class="cls-2" x1="463.73" y1="148.4" x2="463.68" y2="105.3"/>
  <polygon class="cls-30" points="301.6 163.21 299.95 161.86 299.95 161.86 296.96 159.42 296.96 159.42 296.94 159.4 291.51 154.97 264.67 155 261.2 154.99 261.21 154.99 261.04 154.99 261.05 154.99 257.22 154.99 237.92 155.12 237.92 177.69 219.38 177.78 219.43 212.39 251.62 212.72 252.06 212.66 252.06 212.67 250.73 212.78 250.82 224.92 250.79 228.16 269.93 228.16 269.93 222.49 270.22 222.49 270.79 222.49 271.64 222.49 278.03 222.66 278.15 223.38 278.27 224.25 278.33 225.63 284.72 227.04 284.73 191.94 301.14 191.92 301.22 189.89 301.22 189.89 301.28 186.02 301.28 186.02 301.28 185.94 301.28 185.94 301.33 182.07 301.6 163.21"/>
  <polygon points="301.25 181.02 301.25 180.65 285.27 180.65 285.27 175.3 287.78 175.3 300.87 175.3 300.87 181.02 301.25 181.02 301.25 180.65 301.25 181.02 301.62 181.02 301.62 174.56 287.78 174.56 284.52 174.56 284.52 181.4 301.62 181.4 301.62 181.02 301.25 181.02"/>
  <path d="M284.98,226.84v-34.67s79.87,0,79.87,0v-30.63s6.75-6.52,6.75-6.52l25.11,.06v10.43s18.72,0,18.72,0l.19,61.98h-4.03s.03-4.61,.03-4.61h-27.2s-.01,.36-.01,.36c0,0,0,.13-.02,.37-.05,.84-.21,3.03-.75,5.47-.54,2.43-1.46,5.11-2.97,6.93-3.26,3.94-8.9,8.67-17.46,8.67-.75,0-1.52-.04-2.31-.11-2.46-.23-8.89-1.57-17.09-3.44-12.32-2.8-28.68-6.8-41.96-10.1-6.64-1.65-12.51-3.13-16.72-4.19-2.11-.53-3.8-.96-4.96-1.25-1.16-.3-1.8-.46-1.8-.46l-.09,.36h.37v-3.23h-9v5.4s-18.49,.04-18.49,.04l-.06-14.77-45.94-.13v14.85s-17.25-.02-17.25-.02l.05-5.39h-10.95s-.02,3.84-.02,3.84h.37s-.09-.36-.09-.36c-60.62,14.76-60.87,14.68-78.41,18.58-1.99,.44-3.85,.64-5.6,.64-5.41,0-9.71-1.87-12.92-4.16-3.21-2.28-5.34-4.99-6.36-6.6-2.54-4-2.88-7.77-2.88-9.51,0-.32,.01-.57,.02-.74,0-.09,.01-.15,.02-.19v-.06s.06-.42,.06-.42h-26.69v3.99s-4.43,0-4.43,0v-51.81s.15-12.13,.15-12.13v-8.07s42.74,0,42.74,0l4.95,4.55v36.69h70.51v-37.29s5.09-4.1,5.09-4.1l53.35,.02,73.89-.04,10.26,8.38-.4,28.06h.74s.4-28.41,.4-28.41l-10.74-8.78-74.15,.04-53.61-.02-5.57,4.49v36.9s-69.02,0-69.02,0v-36.27s-5.41-4.97-5.41-4.97H39.44v8.81s-.15,12.13-.15,12.13v52.56h5.92v-3.99s25.52,0,25.52,0v-.37l-.37-.05s-.05,.41-.05,1.09c0,1.83,.36,5.75,3,9.9,1.07,1.69,3.25,4.45,6.56,6.81,3.31,2.35,7.77,4.3,13.36,4.29,1.81,0,3.73-.2,5.76-.66,17.54-3.91,17.81-3.83,78.42-18.58l.28-.07,.02-3.39h9.46s-.05,5.39-.05,5.39l18.76,.02v-14.85s44.44,.12,44.44,.12l.06,14.78,19.98-.05v-5.4s7.51,0,7.51,0v2.78s.28,.07,.28,.07c0,0,18.14,4.62,37.54,9.37,9.7,2.37,19.71,4.77,27.93,6.64,8.22,1.87,14.62,3.21,17.18,3.45,.82,.08,1.61,.12,2.38,.12,8.85,0,14.71-4.91,18.04-8.94,1.86-2.26,2.82-5.55,3.33-8.29,.51-2.74,.57-4.92,.57-4.93h-.37s0,.36,0,.36h26.09s-.03,4.61-.03,4.61h5.53s-.19-63.47-.19-63.47h-18.72s0-10.44,0-10.44l-26.16-.06-7.2,6.95v30.21s-79.87,0-79.87,0v35.41h.74"/>
  <text class="cls-27" transform="translate(487.49 227.57)"><tspan x="0" y="0">DCOTA 2nd Floor</tspan></text>
  <text class="cls-8" transform="translate(309.17 152.91)"><tspan x="0" y="0">ATRIUM B</tspan></text>
  <text class="cls-8" transform="translate(94.3 156.01)"><tspan x="0" y="0">ATRIUM A</tspan></text>
  <text class="cls-8" transform="translate(500.3 152.76)"><tspan x="0" y="0">ATRIUM C</tspan></text>
  <g>
    <rect x="135.55" y="137.07" width="12.07" height="12.07"/>
    <path class="cls-25" d="M140.12,138c-.04-.05-.11-.08-.19-.08s-.15,.03-.19,.08l-1.01,1.32c-.04,.05-.04,.11,0,.16,.04,.05,.11,.08,.18,.08h.49s0,1.22,0,1.22h1.07v-1.22s.47,0,.47,0c.08,0,.15-.03,.19-.08,.04-.05,.04-.11,0-.16l-1.01-1.32Z"/>
    <path class="cls-25" d="M143.05,140.71c.04,.05,.11,.08,.19,.08s.15-.03,.19-.08l1.01-1.32s.04-.11,0-.16c-.04-.05-.11-.08-.18-.08h-.49v-1.22h-1.07v1.22s-.47,0-.47,0c-.08,0-.15,.03-.19,.08-.04,.05-.04,.11,0,.16l1.01,1.32Z"/>
    <path class="cls-25" d="M145.55,141.3h-7.93c-.67,0-1.22,.55-1.22,1.22v4.55c0,.67,.55,1.22,1.22,1.22h7.93c.67,0,1.22-.55,1.22-1.22v-4.55c0-.68-.55-1.22-1.22-1.22m0,.47c.42,0,.75,.34,.75,.76v4.55c0,.42-.34,.76-.75,.76h-7.93c-.42,0-.76-.34-.76-.76v-4.55c0-.42,.34-.76,.76-.76h7.93Z"/>
    <path class="cls-25" d="M140.69,143.2c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M140.54,144.35c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.58-2"/>
    <path class="cls-25" d="M144.12,143.2c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M143.96,144.35c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.59-2"/>
  </g>
  <g>
    <rect x="136.44" y="155.98" width="12.07" height="12.07"/>
    <path class="cls-25" d="M141.02,156.91c-.04-.05-.11-.08-.19-.08s-.15,.03-.19,.08l-1.01,1.32c-.04,.05-.04,.11,0,.16,.04,.05,.11,.08,.18,.08h.49s0,1.22,0,1.22h1.07v-1.22s.47,0,.47,0c.08,0,.15-.03,.19-.08,.04-.05,.04-.11,0-.16l-1.01-1.32Z"/>
    <path class="cls-25" d="M143.94,159.62c.04,.05,.11,.08,.19,.08s.15-.03,.19-.08l1.01-1.32s.04-.11,0-.16c-.04-.05-.11-.08-.18-.08h-.49v-1.22h-1.07v1.22s-.47,0-.47,0c-.08,0-.15,.03-.19,.08-.04,.05-.04,.11,0,.16l1.01,1.32Z"/>
    <path class="cls-25" d="M146.44,160.21h-7.93c-.67,0-1.22,.55-1.22,1.22v4.55c0,.67,.55,1.22,1.22,1.22h7.93c.67,0,1.22-.55,1.22-1.22v-4.55c0-.68-.55-1.22-1.22-1.22m0,.47c.42,0,.75,.34,.75,.76v4.55c0,.42-.34,.76-.75,.76h-7.93c-.42,0-.76-.34-.76-.76v-4.55c0-.42,.34-.76,.76-.76h7.93Z"/>
    <path class="cls-25" d="M141.59,162.11c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M141.43,163.26c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.58-2"/>
    <path class="cls-25" d="M145.01,162.11c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M144.86,163.26c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.59-2"/>
  </g>
  <g>
    <rect x="526.57" y="95.12" width="12.07" height="12.07"/>
    <path class="cls-25" d="M531.15,96.05s-.11-.08-.19-.08-.15,.03-.19,.08l-1.01,1.32s-.04,.11,0,.16c.04,.05,.11,.08,.18,.08h.49s0,1.22,0,1.22h1.07v-1.22s.47,0,.47,0c.08,0,.15-.03,.19-.08,.04-.05,.04-.11,0-.16l-1.01-1.32Z"/>
    <path class="cls-25" d="M534.07,98.76c.04,.05,.11,.08,.19,.08s.15-.03,.19-.08l1.01-1.32c.04-.05,.04-.11,0-.16-.04-.05-.11-.08-.18-.08h-.49s0-1.22,0-1.22h-1.07v1.22s-.47,0-.47,0c-.08,0-.15,.03-.19,.08-.04,.05-.04,.11,0,.16l1.01,1.32Z"/>
    <path class="cls-25" d="M536.57,99.35h-7.93c-.67,0-1.22,.55-1.22,1.22v4.55c0,.68,.55,1.22,1.22,1.22h7.93c.67,0,1.22-.55,1.22-1.22v-4.55c0-.68-.55-1.22-1.22-1.22m0,.47c.42,0,.75,.34,.75,.76v4.55c0,.42-.34,.76-.75,.76h-7.93c-.42,0-.76-.34-.76-.76v-4.55c0-.42,.34-.76,.76-.76h7.93Z"/>
    <path class="cls-25" d="M531.72,101.25c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M531.56,102.4c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.58-2"/>
    <path class="cls-25" d="M535.14,101.25c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M534.99,102.4c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.59-2"/>
  </g>
  <g>
    <rect x="526.57" y="119.83" width="12.07" height="12.07"/>
    <path class="cls-25" d="M531.15,120.76s-.11-.08-.19-.08-.15,.03-.19,.08l-1.01,1.32s-.04,.11,0,.16c.04,.05,.11,.08,.18,.08h.49s0,1.22,0,1.22h1.07v-1.22s.47,0,.47,0c.08,0,.15-.03,.19-.08,.04-.05,.04-.11,0-.16l-1.01-1.32Z"/>
    <path class="cls-25" d="M534.07,123.47c.04,.05,.11,.08,.19,.08s.15-.03,.19-.08l1.01-1.32c.04-.05,.04-.11,0-.16-.04-.05-.11-.08-.18-.08h-.49s0-1.22,0-1.22h-1.07v1.22s-.47,0-.47,0c-.08,0-.15,.03-.19,.08-.04,.05-.04,.11,0,.16l1.01,1.32Z"/>
    <path class="cls-25" d="M536.57,124.05h-7.93c-.67,0-1.22,.55-1.22,1.22v4.55c0,.68,.55,1.22,1.22,1.22h7.93c.67,0,1.22-.55,1.22-1.22v-4.55c0-.68-.55-1.22-1.22-1.22m0,.47c.42,0,.75,.34,.75,.76v4.55c0,.42-.34,.76-.75,.76h-7.93c-.42,0-.76-.34-.76-.76v-4.55c0-.42,.34-.76,.76-.76h7.93Z"/>
    <path class="cls-25" d="M531.72,125.96c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M531.56,127.1c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.58-2"/>
    <path class="cls-25" d="M535.14,125.96c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M534.99,127.1c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.59-2"/>
  </g>
  <g>
    <rect x="346.41" y="131.09" width="12.07" height="12.07"/>
    <path class="cls-25" d="M350.98,132.02s-.11-.08-.19-.08-.15,.03-.19,.08l-1.01,1.32s-.04,.11,0,.16c.04,.05,.11,.08,.18,.08h.49s0,1.22,0,1.22h1.07v-1.22s.47,0,.47,0c.08,0,.15-.03,.19-.08,.04-.05,.04-.11,0-.16l-1.01-1.32Z"/>
    <path class="cls-25" d="M353.9,134.73c.04,.05,.11,.08,.19,.08s.15-.03,.19-.08l1.01-1.32c.04-.05,.04-.11,0-.16-.04-.05-.11-.08-.18-.08h-.49s0-1.22,0-1.22h-1.07v1.22s-.47,0-.47,0c-.08,0-.15,.03-.19,.08-.04,.05-.04,.11,0,.16l1.01,1.32Z"/>
    <path class="cls-25" d="M356.41,135.32h-7.93c-.67,0-1.22,.55-1.22,1.22v4.55c0,.68,.55,1.22,1.22,1.22h7.93c.67,0,1.22-.55,1.22-1.22v-4.55c0-.68-.55-1.22-1.22-1.22m0,.47c.42,0,.75,.34,.75,.76v4.55c0,.42-.34,.76-.75,.76h-7.93c-.42,0-.76-.34-.76-.76v-4.55c0-.42,.34-.76,.76-.76h7.93Z"/>
    <path class="cls-25" d="M351.55,137.22c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M351.39,138.37c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.58-2"/>
    <path class="cls-25" d="M354.97,137.22c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M354.82,138.37c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.59-2"/>
  </g>
  <g>
    <rect x="346.41" y="155.79" width="12.07" height="12.07"/>
    <path class="cls-25" d="M350.98,156.72s-.11-.08-.19-.08-.15,.03-.19,.08l-1.01,1.32s-.04,.11,0,.16c.04,.05,.11,.08,.18,.08h.49s0,1.22,0,1.22h1.07v-1.22s.47,0,.47,0c.08,0,.15-.03,.19-.08,.04-.05,.04-.11,0-.16l-1.01-1.32Z"/>
    <path class="cls-25" d="M353.9,159.43c.04,.05,.11,.08,.19,.08s.15-.03,.19-.08l1.01-1.32c.04-.05,.04-.11,0-.16-.04-.05-.11-.08-.18-.08h-.49s0-1.22,0-1.22h-1.07v1.22s-.47,0-.47,0c-.08,0-.15,.03-.19,.08-.04,.05-.04,.11,0,.16l1.01,1.32Z"/>
    <path class="cls-25" d="M356.41,160.02h-7.93c-.67,0-1.22,.55-1.22,1.22v4.55c0,.68,.55,1.22,1.22,1.22h7.93c.67,0,1.22-.55,1.22-1.22v-4.55c0-.68-.55-1.22-1.22-1.22m0,.47c.42,0,.75,.34,.75,.76v4.55c0,.42-.34,.76-.75,.76h-7.93c-.42,0-.76-.34-.76-.76v-4.55c0-.42,.34-.76,.76-.76h7.93Z"/>
    <path class="cls-25" d="M351.55,161.92c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M351.39,163.07c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.58-2"/>
    <path class="cls-25" d="M354.97,161.92c0-.56-.37-.84-.82-.84s-.82,.28-.82,.84c0,1.12,1.64,1.12,1.64,0"/>
    <path class="cls-25" d="M354.82,163.07c-.12-.05-.31-.08-.5-.09h-.32c-.2,.01-.38,.04-.5,.09-.72,.3-.59,1.26-.59,2,0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35l.14,.98c0,.14,.08,.25,.18,.25h.68c.1,0,.18-.11,.18-.25l.14-.98c0,.17,.11,.32,.26,.34,.01,0,.02,0,.03,0,.16,0,.29-.15,.29-.35,0-.76,.13-1.7-.59-2"/>
  </g>
  <g>
    <rect x="171.22" y="142.27" width="16.46" height="17.16"/>
    <path class="cls-25" d="M181.95,154.09c-.29,0-.56,0-.83,0-.23,0-.32-.1-.28-.33,.18-.98,.37-1.95,.55-2.93,.15-.82,.3-1.64,.46-2.47h-.26c-.07,.36-.14,.72-.21,1.08-.12,.61-.23,1.22-.35,1.83-.03,.12-.08,.24-.15,.34-.12,.18-.29,.28-.51,.24-.23-.05-.35-.22-.39-.43-.03-.14-.03-.29,0-.42,.22-1.19,.45-2.38,.68-3.56,.08-.41,.25-.59,.65-.66,.22-.04,.44-.07,.66-.08,.86,0,1.72,0,2.57,.01,.16,0,.32,.04,.48,.07,.35,.05,.54,.27,.6,.6,.17,.85,.33,1.7,.49,2.56,.07,.36,.14,.72,.21,1.09,.04,.2,.03,.4-.08,.58-.17,.29-.51,.35-.74,.1-.11-.12-.2-.29-.23-.45-.19-.92-.36-1.84-.54-2.76,0-.05-.02-.09-.03-.14h-.25c.09,.48,.18,.96,.27,1.43,.25,1.31,.49,2.63,.74,3.94,.05,.26-.04,.36-.3,.36-.26,0-.52,0-.8,0v.19c0,1.05,0,2.1,0,3.14,0,.13-.03,.26-.08,.38-.08,.17-.23,.28-.42,.28-.2,0-.35-.1-.43-.27-.06-.12-.09-.27-.09-.41,0-1.04,0-2.08,0-3.11v-.19h-.33v.18c0,1.04,0,2.07,0,3.11,0,.13-.03,.27-.08,.4-.08,.18-.23,.29-.44,.29s-.35-.11-.43-.29c-.05-.11-.08-.24-.08-.37,0-1.04,0-2.08,0-3.13v-.21Z"/>
    <path class="cls-25" d="M175.44,152.52h-.19v.19c0,1.55,0,3.1,0,4.64,0,.35-.19,.62-.48,.7-.34,.09-.69-.12-.77-.48-.02-.1-.03-.2-.03-.3,0-2.99,0-5.98,0-8.97v-.21h-.23v.19c0,1.18,0,2.35,0,3.52,0,.22-.05,.42-.21,.58-.23,.23-.55,.23-.77,0-.13-.14-.2-.31-.2-.5,0-1.28,0-2.56,0-3.84,0-.72,.61-1.34,1.33-1.35,.97-.02,1.94-.02,2.91,0,.71,.01,1.32,.63,1.32,1.34,.01,1.28,0,2.57,0,3.86,0,.03,0,.05,0,.08-.04,.34-.3,.6-.59,.6-.29,0-.54-.27-.57-.61,0-.06,0-.13,0-.19v-3.46s0-.19,0-.19h-.23v.21c0,3,0,6,0,9,0,.09,0,.19-.03,.28-.08,.3-.36,.51-.66,.48-.31-.02-.56-.29-.59-.62,0-.06,0-.12,0-.17v-4.57s0-.2,0-.2Z"/>
    <path class="cls-25" d="M183.14,146.26c-.73,0-1.32-.6-1.32-1.33,0-.73,.6-1.32,1.33-1.32,.72,0,1.31,.6,1.32,1.32,0,.73-.59,1.33-1.33,1.33"/>
    <path class="cls-25" d="M174.02,144.92c0-.73,.6-1.32,1.33-1.32,.73,0,1.32,.6,1.31,1.34,0,.72-.6,1.31-1.33,1.31-.73,0-1.33-.6-1.32-1.33"/>
  </g>
  <g>
    <rect x="398.43" y="142.27" width="16.46" height="17.16"/>
    <path class="cls-25" d="M409.15,154.09c-.29,0-.56,0-.83,0-.23,0-.32-.1-.28-.33,.18-.98,.37-1.95,.55-2.93,.15-.82,.3-1.64,.46-2.47h-.26c-.07,.36-.14,.72-.21,1.08-.12,.61-.23,1.22-.35,1.83-.03,.12-.08,.24-.15,.34-.12,.18-.29,.28-.51,.24-.23-.05-.35-.22-.39-.43-.03-.14-.03-.29,0-.42,.22-1.19,.45-2.38,.68-3.56,.08-.41,.25-.59,.65-.66,.22-.04,.44-.07,.66-.08,.86,0,1.72,0,2.57,.01,.16,0,.32,.04,.48,.07,.35,.05,.53,.27,.6,.6,.17,.85,.33,1.7,.49,2.56,.07,.36,.14,.72,.21,1.09,.04,.2,.03,.4-.08,.58-.17,.29-.51,.35-.74,.1-.11-.12-.2-.29-.23-.45-.19-.92-.36-1.84-.54-2.76,0-.05-.02-.09-.03-.14h-.25c.09,.48,.18,.96,.27,1.43,.24,1.31,.49,2.63,.74,3.94,.05,.26-.04,.36-.31,.36-.26,0-.51,0-.8,0v.19c0,1.05,0,2.1,0,3.14,0,.13-.03,.26-.08,.38-.08,.17-.23,.28-.42,.28-.2,0-.35-.1-.43-.27-.06-.12-.09-.27-.09-.41,0-1.04,0-2.08,0-3.11v-.19h-.33v.18c0,1.04,0,2.07,0,3.11,0,.13-.03,.27-.08,.4-.08,.18-.23,.29-.44,.29s-.35-.11-.43-.29c-.05-.11-.08-.24-.08-.37,0-1.04,0-2.08,0-3.13v-.21Z"/>
    <path class="cls-25" d="M402.65,152.52h-.19v.19c0,1.55,0,3.1,0,4.64,0,.35-.19,.62-.48,.7-.34,.09-.69-.12-.77-.48-.02-.1-.03-.2-.03-.3,0-2.99,0-5.98,0-8.97v-.21h-.23v.19c0,1.18,0,2.35,0,3.52,0,.22-.05,.42-.21,.58-.23,.23-.55,.23-.77,0-.13-.14-.2-.31-.2-.5,0-1.28,0-2.56,0-3.84,0-.72,.61-1.34,1.33-1.35,.97-.02,1.94-.02,2.91,0,.71,.01,1.32,.63,1.32,1.34,.01,1.28,0,2.57,0,3.86,0,.03,0,.05,0,.08-.04,.34-.3,.6-.59,.6-.29,0-.54-.27-.57-.61,0-.06,0-.13,0-.19v-3.46s0-.19,0-.19h-.23v.21c0,3,0,6,0,9,0,.09,0,.19-.03,.28-.08,.3-.36,.51-.66,.48-.31-.02-.56-.29-.59-.62,0-.06,0-.12,0-.17v-4.57s0-.2,0-.2Z"/>
    <path class="cls-25" d="M410.35,146.26c-.73,0-1.32-.6-1.32-1.33,0-.73,.6-1.32,1.33-1.32,.72,0,1.31,.6,1.32,1.32,0,.73-.59,1.33-1.33,1.33"/>
    <path class="cls-25" d="M401.23,144.92c0-.73,.6-1.32,1.33-1.32,.73,0,1.32,.6,1.31,1.34,0,.72-.6,1.31-1.32,1.31-.73,0-1.33-.6-1.32-1.33"/>
  </g>
  <polygon points="528.57 78.17 528.56 78.54 543.59 78.6 543.65 102.66 552.5 111.18 596.98 111.07 596.98 94.52 593.44 94.52 593.66 92.62 596.57 92.62 596.57 73.8 591.7 73.55 582.79 44.06 534.88 44.06 534.59 46.61 532.11 46.61 531.84 34.86 508.9 34.86 508.42 47.35 505.81 47.16 505.88 44.14 476.96 44.14 476.65 48.18 444.38 56.37 444.45 89.26 447.87 92.3 447.62 149.12 488.29 149.17 497.68 141.33 497.68 78.46 512.3 78.48 528.56 78.54 528.57 78.17 528.57 77.8 512.31 77.73 496.94 77.71 496.94 140.98 488.02 148.42 448.36 148.38 448.61 91.97 445.2 88.93 445.12 56.95 477.35 48.77 477.65 44.89 505.12 44.89 505.05 47.86 509.13 48.15 509.61 35.6 531.11 35.6 531.38 47.36 535.25 47.36 535.54 44.8 582.24 44.8 591.14 74.27 595.83 74.51 595.83 91.88 593 91.88 592.61 95.27 596.24 95.27 596.23 110.32 552.8 110.44 544.4 102.34 544.33 77.86 528.57 77.8 528.57 78.17"/>
  <rect x="543.96" y="133.72" width="51.6" height=".75"/>
  <g>
    <rect x="579.1" y="103.94" width="16.46" height="17.16"/>
    <path class="cls-25" d="M589.83,115.77c-.29,0-.56,0-.83,0-.23,0-.32-.1-.28-.33,.18-.98,.37-1.95,.55-2.93,.15-.82,.3-1.64,.46-2.47h-.26c-.07,.36-.14,.72-.21,1.08-.12,.61-.23,1.22-.35,1.83-.03,.12-.08,.24-.15,.34-.12,.18-.29,.28-.51,.24-.23-.05-.35-.22-.39-.43-.03-.14-.03-.29,0-.42,.22-1.19,.45-2.38,.68-3.56,.08-.41,.25-.59,.65-.66,.22-.04,.44-.07,.66-.08,.86,0,1.72,0,2.57,.01,.16,0,.32,.04,.48,.07,.35,.05,.53,.27,.6,.6,.17,.85,.33,1.7,.49,2.56,.07,.36,.14,.72,.21,1.09,.04,.2,.03,.4-.08,.58-.17,.29-.51,.35-.74,.1-.11-.12-.2-.29-.23-.45-.19-.92-.36-1.84-.54-2.76,0-.05-.02-.09-.03-.14h-.25c.09,.48,.18,.96,.27,1.43,.24,1.31,.49,2.63,.74,3.94,.05,.26-.04,.36-.31,.36-.26,0-.51,0-.8,0v.19c0,1.05,0,2.1,0,3.14,0,.13-.03,.26-.08,.38-.08,.17-.23,.28-.42,.28-.2,0-.35-.1-.43-.27-.06-.12-.09-.27-.09-.41,0-1.04,0-2.08,0-3.11v-.19h-.33v.18c0,1.04,0,2.07,0,3.11,0,.13-.03,.27-.08,.4-.08,.18-.23,.29-.44,.29s-.35-.11-.43-.29c-.05-.11-.08-.24-.08-.37,0-1.04,0-2.08,0-3.13v-.21Z"/>
    <path class="cls-25" d="M583.32,114.19h-.19v.19c0,1.55,0,3.1,0,4.64,0,.35-.19,.62-.48,.7-.34,.09-.69-.12-.77-.48-.02-.1-.03-.2-.03-.3,0-2.99,0-5.98,0-8.97v-.21h-.23v.19c0,1.18,0,2.35,0,3.52,0,.22-.05,.42-.21,.58-.23,.23-.55,.23-.77,0-.13-.14-.2-.31-.2-.5,0-1.28,0-2.56,0-3.84,0-.72,.61-1.34,1.33-1.35,.97-.02,1.94-.02,2.91,0,.71,.01,1.32,.63,1.32,1.34,.01,1.28,0,2.57,0,3.86,0,.03,0,.05,0,.08-.04,.34-.3,.6-.59,.6-.29,0-.54-.27-.57-.61,0-.06,0-.13,0-.19v-3.46s0-.19,0-.19h-.23v.21c0,3,0,6,0,9,0,.09,0,.19-.03,.28-.08,.3-.36,.51-.66,.48-.31-.02-.56-.29-.59-.62,0-.06,0-.12,0-.17v-4.57s0-.2,0-.2Z"/>
    <path class="cls-25" d="M591.03,107.94c-.73,0-1.32-.6-1.32-1.33,0-.73,.6-1.32,1.33-1.32,.72,0,1.31,.6,1.32,1.32,0,.73-.59,1.33-1.33,1.33"/>
    <path class="cls-25" d="M581.9,106.6c0-.73,.6-1.32,1.33-1.32,.73,0,1.32,.6,1.31,1.34,0,.72-.6,1.31-1.32,1.31-.73,0-1.33-.6-1.32-1.33"/>
  </g>
  <rect class="cls-6" x=".12" y=".12" width="636" height="285.33"/>
  <path d="M444.88,88.32h-28.84v-13.02h-5.11v4.81h-19.56v-3.29l-.28-.07s-16.61-4.09-33.84-8.25c-8.62-2.08-17.39-4.17-24.32-5.77-3.47-.8-6.47-1.48-8.77-1.98-.25-.05-.49-.1-.72-.15h0c-3.02-.83-6.99-1.73-9.08-2.16-.31-.09-1.78-.45-4.27-.45s-6.3,.4-10.93,1.92c-4.86,1.59-8.06,4.49-10.27,7.33-1.11,1.42-1.97,2.84-2.67,4.07-.65,1.14-1.17,2.12-1.6,2.81l-25.77-.03-3.59,15.66-5.09,.24v9.13l-45.27-.49,.02,50.51h85.87l11.73-8.78v-29.17h62.09v31.31l6.91,6.6h20.08v-13.47h8.2s1.57,1.61,1.57,1.61l14.25-.02,.04,11.9h.37s32.37-.08,32.37-.08v-51.92l.06-4.98-3.55-3.86Zm2.75,8.83v51.18l-31.26,.07-.04-11.9-14.68,.02-1.57-1.61h-9.26s0,13.46,0,13.46h-19.03l-6.46-6.17v-31.74h-63.58v29.55l-11.24,8.41h-84.88l-.02-49.02,45.27,.49v-9.17l4.94-.24,3.58-15.63,25.58,.03,.11-.17c.5-.75,1.04-1.81,1.74-3.02,2.08-3.66,5.49-8.76,12.53-11.06,4.56-1.49,8.18-1.88,10.7-1.88,1.21,0,2.14,.09,2.83,.19h0c.2,.02,1.79,.35,3.88,.82,.63,.14,.93,.17,1.61,.37,1.58,.37,3.29,.79,4.84,1.21h0c.24,.05,.49,.11,.76,.17,8.03,1.73,24.76,5.72,39.48,9.29,7.36,1.78,14.22,3.46,19.24,4.69,2.51,.61,4.56,1.12,5.98,1.47,.93,.23,1.59,.39,1.93,.48v3.45h21.05v-4.81h3.62v13.02h29.26l3.13,3.4-.06,4.69Z"/>
  <line class="cls-4" x1="512.36" y1="63.03" x2="529.31" y2="63.03"/>
  <line class="cls-4" x1="219.38" y1="177.78" x2="219.38" y2="213.6"/>
  <rect x="545.12" y="154.7" width=".75" height="27.07" transform="translate(-15.91 64.84) rotate(-6.71)"/>


   <polygon v-on:click="clickStore($event)" id="C-236" class="cls-26 cls0-26" points="575.74 100.81 575.86 94.95 596.34 94.95 593.15 94.84 593.47 92.35 596.34 92.22 596.27 83 569.13 83 561.48 90.7 543.92 90.7 543.92 102.5 552.79 110.93 570.16 110.87 571.12 110.84 571.28 100.77 575.74 100.81"/>
  <polygon v-on:click="clickStore($event)" id="C-212" class="cls-26 cls0-26" points="472.52 113.83 463.74 105.28 463.74 148.78 488.04 148.86 497.3 141.07 497.3 113.83 472.52 113.83"/>
  <polygon v-on:click="clickStore($event)" id="C-262" class="cls-26 cls0-26" points="486.31 154.84 486.31 198.13 488.48 197.64 497.31 195.85 504.59 194.19 529.51 188.54 529.51 154.81 486.31 154.84"/>
  <polygon v-on:click="clickStore($event)" id="B-218" class="cls-29 cls0-29" points="272.58 80.18 272.58 89.89 268.29 89.89 268.29 103.23 273.27 103.23 284.3 114.27 284.34 121.1 299.16 135.82 302.17 135.82 301.98 110.95 300.41 110.95 300.41 110.79 300.41 106.86 284.67 91.08 284.67 90.95 284.62 90.9 284.67 82.87 284.67 80.18 272.58 80.18"/>
  <polygon v-on:click="clickStore($event)" id="B-228" class="cls-29 cls0-29" points="332.35 62.89 325.8 61.37 320.04 60.31 316.3 59.24 313.84 58.64 309.05 58.44 306.29 58.53 302.92 59.15 299.9 60.13 296.88 61.2 294.12 62.62 291.4 64.54 289.6 67.18 287.62 69.65 285.91 72.33 284.72 74.78 284.62 90.9 300.41 106.86 300.41 110.95 302.1 110.95 302.24 111 302.24 110.98 317.46 110.82 348.04 110.87 348.04 66.69 332.35 62.89"/>
  <polygon v-on:click="clickStore($event)" id="B-240" class="cls-29 cls0-29" points="416.2 89.11 415.64 89.11 415.57 76.27 413.23 76.22 411.03 76.22 411.03 80.96 411.03 80.96 411.03 80.96 390.94 80.96 390.94 113.45 386.65 113.45 386.65 119.9 364.76 119.9 364.76 142.6 371.45 149.44 387.9 149.36 388.74 149.36 391.05 149.27 390.97 135.84 397.35 135.84 399.81 135.84 401.42 137.37 415.72 137.37 415.73 137.76 416.2 137.37 416.2 89.11"/>
  <polygon v-on:click="clickStore($event)" id="B-254" class="cls-29 cls0-29" points="397.23 202.07 416.17 202.07 416.13 175.43 415.98 165.29 397.3 165.29 397.3 154.91 389 154.84 387.92 154.99 371.71 154.91 367.78 158.64 364.79 161.45 364.79 162.14 364.83 174.73 371.8 174.73 397.23 202.07"/>
  <polygon v-on:click="clickStore($event)" id="B-260" class="cls-29 cls0-29" points="415.62 201.84 396.9 201.84 371.47 174.5 364.1 174.5 364.1 191.44 284.2 191.76 284.39 226.81 306.45 232.35 332.64 238.69 352.08 243.19 355.32 243.82 359.28 244.59 361.76 244.81 366.3 244.63 369.32 244 371.07 243.28 374.54 241.48 377.19 239.59 380.39 236.49 382.14 233.79 383.22 230.95 384.08 226.72 384.45 223.03 410.85 223.03 410.85 227.53 415.62 227.62 415.62 201.84"/>
  <polygon v-on:click="clickStore($event)" id="C-270" class="cls-26 cls0-26" points="458.93 154.86 458.93 185.79 459.44 187.42 460.83 190.33 462.19 191.96 463.79 193.93 466.51 196.29 470.7 198.57 475.48 199.67 481.65 199.16 486.31 198.13 486.31 154.84 458.93 154.86"/>
  <polygon v-on:click="clickStore($event)" id="C-272" class="cls-26 cls0-26" points="447.48 154.86 447.63 175.74 444.89 175.67 444.89 185.03 458.74 185.19 458.93 185.79 458.93 154.86 447.48 154.86"/>
  <polygon v-on:click="clickStore($event)" id="C-250" class="cls-26 cls0-26" points="595.77 126.45 593.28 126.51 593.15 124.72 596.47 124.72 596.47 115.97 592.19 115.97 581.02 115.85 570.29 115.91 553.5 115.97 552.6 116.04 543.98 126.38 543.97 134.1 595.78 134.1 595.77 126.45"/>
  <polygon v-on:click="clickStore($event)" id="C-214" class="cls-26 cls0-26" points="474.47 97.66 474.47 88.66 444.92 88.71 444.95 89.03 445.27 89.35 448.42 92.18 448.01 96.51 448.01 97.09 455.27 97.04 472.52 113.83 497.3 113.83 497.3 97.66 474.59 97.66 474.47 97.66"/>
  <polygon v-on:click="clickStore($event)" id="C-220" class="cls-26 cls0-26" points="497.37 78.17 499.07 78.14 499.07 70.35 489.83 61.2 479.74 61.2 479.74 49.39 477.01 48.35 477 48.48 444.82 56.78 444.89 88.27 444.91 88.55 445.19 88.66 474.47 88.66 474.59 97.66 497.3 97.66 497.3 95.35 497.37 78.17"/>
  <polygon v-on:click="clickStore($event)" id="C-224" class="cls-26 cls0-26" points="508.47 48.77 508.11 47.52 504.98 47.27 504.98 44.46 476.82 44.52 476.51 48.22 479.25 49.26 479.25 61.08 489.33 61.08 498.57 70.23 498.57 78.01 503.19 77.92 511.88 77.96 511.88 48.89 508.47 48.77"/>
  <polygon v-on:click="clickStore($event)" id="C-230" class="cls-26 cls0-26" points="550.91 61.38 561.79 61.36 561.79 44.59 540.79 44.59 535.23 44.46 535.04 47.08 531.72 47.08 529.04 49.95 529.11 78.17 541.43 78.39 541.43 70.94 550.91 61.38"/>
  <polygon v-on:click="clickStore($event)" id="C-228A" class="cls-26 cls0-26" points="517.6 49.02 512.38 49.02 512.38 63.03 529.01 63.03 529.04 49.19 517.6 49.02"/>
  <polygon v-on:click="clickStore($event)" id="C-228B" class="cls-26 cls0-26" points="512.38 78.09 528.98 78.17 529.01 63.03 512.38 63.03 512.38 65.92 512.38 78.09"/>
  <polygon v-on:click="clickStore($event)" id="B-216" class="cls-29 cls0-29" points="299.46 135.69 284.64 120.97 284.59 114.14 273.57 103.1 268.59 103.1 268.59 148.71 291.01 148.74 302.46 140.05 302.47 135.69 299.46 135.69"/>
  
  <polygon v-on:click="clickStore($event)" id="C-265" class="cls-26 cls0-26" points="529.51 154.81 529.51 188.54 531.92 188 544.03 185.04 546.92 184.87 547.08 181.68 543.92 154.8 529.51 154.81"/>
  
  <polygon v-on:click="clickStore($event)" id="C-232" class="cls-26 cls0-26" points="596.21 74.21 591.42 73.83 582.48 44.59 561.79 44.59 561.79 61.36 550.91 61.38 541.43 70.94 541.43 78.39 543.92 78.43 543.92 90.7 561.48 90.7 569.13 83 596.27 83 596.21 74.21"/>
  
</svg>
    <store-modal
      :title="store.name"
      :image="store.business_image"
      :suite="store.suite"
      :website="store.website"
      :contact="store.contact"
      :phone="store.phone"
      :email="store.email"
      :target="target"
      :targetzone="targetzone"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import StoreModal from '@/components/StoreModal'
export default {
  name: 'floor2',
  components:{
    StoreModal,
  },
  data(){
    return {
      store:'B-100',
	  targetzone:'C-270',
	  target: null,
    }
  },
  methods:{
     hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
	},
    clickStore(e) {
		   setTimeout(() => {
                  this.$root.$emit('bv::hide::popover');
            }, 0);
      
            this.targetzone = '';

            let num = document.getElementsByClassName("selected").length;
            if(num){


				      const allChildElementsOfParentWithClass = document.querySelectorAll('.selected');



              allChildElementsOfParentWithClass.forEach((element) => {
                element.classList.remove('selected');
              });	
            }
     
      if (e.target.classList == "cls-23") {
			  var className = "cls-23";
        var bodyClass = "section-23";
			} else if(e.target.classList.contains('cls-26')){
			  var className = "cls-26 cls0-26";
        var bodyClass = "section-26";
			}else if (e.target.classList.contains('cls-29')) {
        
          var className = "cls-29 cls0-29";
          var bodyClass = "section-29";
      }

      e.target.className.baseVal = className+" selected";


      document.body.className = '';
      document.body.classList.add(bodyClass);
      this.target = e.target.id;
      this.store = this.storeData(this.target);

	  this.targetzone = this.target;

	  //alert(this.targetzone);
    // this.$bvModal.show('modal-center');
	 		setTimeout(() => {
                  this.$root.$emit('bv::show::popover',this.targetzone);
                  
            }, 500);
    },
    resetModal() {
      console.log("reset modal");
    },

    storeData(payload) {
      
       
      
        return this.stores.find(x => x.suite === payload)

    },
	showStorePopover(){
          this.targetzone = this.$route.params.id;
        if (typeof(this.$route.params.id) !== 'undefined'){
                let zoneString =  this.targetzone.substring(0, 1);
             
               if (zoneString == "B") {
                    var className = "cls-26";
                      var bodyClass = "section-26";
                } else if(zoneString == "C"){
                    var className = "cls-23";
                      var bodyClass = "section-23";
                }
                document.body.className = '';
                document.body.classList.add(bodyClass);

        
                setTimeout(() => {
                    this.store = this.storeData(this.$route.params.id);
                  
                        this.$root.$emit('bv::show::popover',this.targetzone);
              
                    
                    var d = document.querySelector("polygon#"+this.targetzone); //document.getElementById(this.targetzone);
                    d.className.baseVal = className+' selected';
                  }, 400);
              }
      }
  },
  computed:{
    stores() {
      return this.$store.state.suites;
    }
  },
  mounted() {
    window.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  },
  created: function(){
        if (typeof(this.$route.params.id) !== 'undefined'){
          setTimeout(() => {
			        this.showStorePopover()
          }, 400);    
	      }
  }
}
</script>

<style>

   .selected
   {
    stroke: #002868 !important;
    stroke-width: 1px;
    stroke-linejoin: round;
    fill: rgb(255 255 255 / 33%) !important;
    cursor: pointer;
    fill-opacity: .7 !important;
    }
    
         .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
        fill: none;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-6 {
        stroke-miterlimit: 10;
      }

      .cls-1, .cls-3 {
        stroke-width: .3px;
      }

      .cls-1, .cls-4 {
        stroke: #000;
      }

      .cls-7 {
        font-size: 4.97px;
      }

      .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24 {
        font-family: Montserrat-Regular, Montserrat;
      }

      .cls-7, .cls-8, .cls-9, .cls-10, .cls-13, .cls-15, .cls-17, .cls-18, .cls-23, .cls-24 {
        fill: #000100;
      }

      .cls-25 {
        fill: #fff;
      }

      .cls-8 {
        font-size: 6.78px;
      }

      .cls-2 {
        stroke: #020202;
      }

      .cls-2, .cls-4 {
        stroke-width: .75px;
      }

      .cls-3 {
        stroke: #000100;
      }

      .cls-26 {
        fill: #d9e09e;
      }

      .cls-9, .cls-16 {
        font-size: 5.49px;
      }

      .cls-27 {
        font-family: Montserrat-Medium, Montserrat;
        font-size: 10.99px;
        font-weight: 500;
      }

      .cls-10, .cls-11 {
        font-size: 5.38px;
      }

      .cls-28 {
        fill: #e5e8e5;
      }

      .cls-12 {
        font-size: 3.5px;
      }

      .cls-13, .cls-22 {
        font-size: 5.49px;
      }

      .cls-14, .cls-15 {
        font-size: 4.74px;
      }

      .cls-17 {
        font-size: 3.97px;
      }

      .cls-18, .cls-19 {
        font-size: 4px;
      }

      .cls-20 {
        font-size: 4.5px;
      }

      .cls-29 {
        fill: #c4e1f6;
      }

      .cls-6 {
        stroke: #fff;
        stroke-width: .25px;
      }

      .cls-30 {
        fill: #b3d7ed;
      }

      .cls-31 {
        fill: #8fc3e1;
      }

      .cls-21 {
        font-size: 5px;
      }

      .cls-23 {
        font-size: 4.97px;
      }

      .cls-24 {
        font-size: 3.97px;
      }

      .cls-32 {
        fill: #98a9b4;
      }
      .section-26 .popover{
        transform:translate3d(calc(100% - 140px), 150px, 0px) !important;
      }
      .section-23 .popover{
         top: 50% !important;
        right: 50% !important;
        transform: translate(50%,-50%) !important;
        left: auto !important;
      }
      .cls-23 {
          fill: rgb(217, 224, 158, 0.5);
          fill-opacity: 0.1;
      }


      .cls0-29,.cls0-26{
        fill-opacity: 0.0;
      }
    </style>
  